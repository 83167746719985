//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/pages/dashboard/rebalance
// Description: Rebalancing page.
// Created:     FUB, May 26, 2022
//==============================================================================

import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"
//import styled from "styled-components"
import { FaPen, FaTrash } from "react-icons/fa"

import { Page } from "../../../components/layout/page"
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs"
import { Button, ButtonHelp, ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button"
import { Link } from "../../../components/widgets/link"
import { useMembership } from "../../../utils/member-data"
import { formatCurrency, formatDateTime, formatDateVerbose } from "../../../utils/format-helpers"
import { Border } from "../../../components/widgets/container"
import { Popup } from "../../../components/widgets/popup"
import { SignUpButton } from "../../../components/widgets/sign-up-button"
import { AccountAssetAllocationTable, AccountAssetAllocationDownload } from "../../../components/widgets/rebal-asset-alloc"
import { useAccountInfo, usePortfolioInfo } from "../../../utils/account-data"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`REBALANCE: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`REBALANCE: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
const DeleteAccount = ({ id, setVisibility }) => {
    const accountInfo = useAccountInfo(id)

    const cancel = () => setVisibility(false)
    const confirm = () => {
        setVisibility(false)
        accountInfo.delete()

        //navigate("/dashboard/")
        setTimeout(navigate("/dashboard/"), 1000)
    }

    if (!accountInfo) return null

    return (<>
        <Popup title="Delete Account" onCancel={cancel}>
            <p>
                Are you sure you want to delete your
                account <strong>{accountInfo.name}</strong>?
            </p>
            <ButtonSecondary text="delete this account" onClick={confirm} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ButtonPrimary text="cancel" onClick={cancel} />
        </Popup>
    </>)
}

//------------------------------------------------------------------------------
const RenameAccount = ({ id, setVisibility }) => {
    const accountInfo = useAccountInfo(id)

    const [defaultAccountName, setDefaultAccountName] = useState()
    useEffect(() => {
        if (defaultAccountName?.length > 0) return

        const name = accountInfo?.name
        if (!name) return

        DEBUG_MSG(`name='${name}'`)
        setDefaultAccountName(name)
    }, [accountInfo, defaultAccountName])

    const [accountName, setAccountName] = useState()
    useEffect(() => {
        if (typeof (defaultAccountName) === 'undefined') return

        DEBUG_MSG(`defaultAccountName=${defaultAccountName}`)
        setAccountName(defaultAccountName)
    }, [defaultAccountName])

    const cancel = () => setVisibility(false)
    const confirm = () => {
        setVisibility(false)
        const accountName2 = accountName.length > 1 ? accountName : "n/a"
        accountInfo.setName(accountName2)
    }

    if (typeof accountName === 'undefined') return null

    return (<>
        <Popup title="Rename Account" onCancel={cancel}>
            <p>
                Please enter the new name for your account:
            </p>
            <p>
                <input
                    type="text"
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    value={accountName}
                    onChange={(event) => setAccountName(event.target.value)}
                    key="account-name"
                />
            </p>
            <ButtonPrimary text="rename this account" onClick={confirm} />
        </Popup>
    </>)
}

//------------------------------------------------------------------------------
const EditAccountNav = ({ id, setVisibility }) => {
    const accountInfo = useAccountInfo(id)

    const [defaultAccountValue, setDefaultAccountValue] = useState()
    useEffect(() => {
        if (defaultAccountValue > 0) return

        const value = accountInfo?.value
        if (!value) return

        DEBUG_MSG(`value=${value}`)
        setDefaultAccountValue(value?.toFixed(0))
    }, [accountInfo, defaultAccountValue])

    const [accountValue, setAccountValue] = useState()
    useEffect(() => {
        if (typeof (defaultAccountValue) === 'undefined') return

        DEBUG_MSG(`defaultAccountValue=${defaultAccountValue}`)
        setAccountValue(defaultAccountValue)
    }, [defaultAccountValue])

    const cancel = () => setVisibility(false)
    const confirm = () => {
        setVisibility(false)
        const accountValue2 = accountValue >= 1 ? accountValue : 25000
        accountInfo.setTotalValue(accountValue2)
    }

    if (typeof accountValue === 'undefined') return null

    return (<>
        <Popup title="Edit Account Value" onCancel={cancel}>
            <p>
                Please enter the total value of your
                account <strong>{accountInfo.name}</strong>:
            </p>
            <p>
                <input
                    type="number"
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    value={accountValue}
                    onChange={(event) => setAccountValue(event.target.value)}
                    key="account-name"
                />
            </p>
            <ButtonPrimary text="update this account" onClick={confirm} />
        </Popup>
    </>)
}

//------------------------------------------------------------------------------
const RebalancePage = ({ location }) => {

    const [ids, setIds] = useState(null) // we use this to memoize an array containing id
    const id = ids?.[0]
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const id0 = searchParams.get("id")
        try {
            const parsed = parseInt(id0)
            setIds([parsed])
        }
        catch (error) {
            // just ignore
        }
    }, [location])

    const accountInfo = useAccountInfo(id)
    const portfolioInfo = usePortfolioInfo(accountInfo?.portfolio)
    const isPremium = portfolioInfo?.tags?.includes("premium")
    const membership = useMembership()
    const access = isPremium ? membership?.features.premium : membership?.features.basic
    const needsRebal = accountInfo?.needsRebalancing

    const finishRebalancing = () => accountInfo?.finishRebalancing()

    const [deleteVisible, setDeleteVisible] = useState(false)
    const [renameVisible, setRenameVisible] = useState(false)
    const [editNavVisible, setEditNavVisible] = useState(false)
    const deleteAccount = () => setDeleteVisible(true)
    const renameAccount = () => setRenameVisible(true)
    const editAccountNav = () => setEditNavVisible(true)

    // TODO: return to dash when account id does not exist
    /*
    if (memberdata && memberdata?.accounts && portfolioList &&
        (id === null || !account || !portfolioInfo)) {
        navigate("/dashboard/")
        return <p>Rebalancing view not available</p>
    }
    */

    if (!accountInfo || !membership || !portfolioInfo)
        return <p>Loading portfolio data...</p>

    return (
        <Page
            title={accountInfo.name}
            description="Use this tool to rebalance your TuringTrader portfolio."
        >

            <h1>{accountInfo.name}<small><Button text={<FaPen />} onClick={renameAccount} /><Button text={<FaTrash />} onClick={deleteAccount} /></small></h1>
            <Breadcrumbs to="/dashboard/" text="Dashboard" />

            <p>
                <br />
                Account status as of <strong>{formatDateTime(portfolioInfo.updatedLast)}</strong>.
            </p>

            <table>
                <tbody>
                    <tr>
                        <td>Investment portfolio:</td>
                        <td>
                            <Link href={`/portfolios/${accountInfo.portfolio}/`}
                            >
                                {portfolioInfo.author}{" "}
                                {portfolioInfo.title}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Last scheduled rebalancing:</td>
                        <td>
                            <strong>{formatDateVerbose(portfolioInfo.rebalLast)}</strong>, after the close
                        </td>
                    </tr>
                    <tr>
                        <td>Approximated account value:</td>
                        <td>
                            <strong>{formatCurrency(accountInfo.value, 0)}</strong>
                            <Button text={<FaPen />} onClick={editAccountNav} />
                        </td>
                    </tr>
                </tbody>
            </table>

            {access.hasTrialAccess === true && <Border><center>
                <p>
                    You are currently enjoying <strong>trial access</strong> to
                    this portfolio. For continued access, please upgrade to
                    our <strong>Premium membership</strong> .
                </p>
                <ButtonSecondary text="Upgrade Now" to="/sign-up/" />
            </center></Border>}

            {access.hasAccess !== true && <Border><center>
                <p>
                    Your access to this <strong>Premium portfolio</strong> has{" "}
                    <strong>expired</strong>. You can see the asset allocation for
                    this portfolio with our <strong>Premium membership</strong>.
                </p>
                <SignUpButton />
            </center></Border>}

            {access.hasAccess === true && isPremium !== true && <Border><center>
                <p>
                    You are missing out. Our <strong>Premium portfolios</strong>{" "}
                    offer <strong>higher returns</strong> and lower volatility.
                    With these portfolios, you can reach your financial goals
                    sooner and sleep better at night.
                </p>
                <ButtonSecondary text="explore portfolios" to="/portfolios/" />
            </center></Border>}

            <br />
            {access.hasAccess === true && (<>
                <AccountAssetAllocationTable ids={ids} />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <small>
                    <AccountAssetAllocationDownload ids={ids} />
                </small>
            </>)}
            {/* TODO: in case member has no access, show dummy table here */}

            <br />
            {needsRebal && (<>
                <ButtonPrimary text="finish rebalancing" onClick={finishRebalancing} />
                &nbsp;&nbsp;&nbsp;&nbsp;
            </>)}
            <ButtonHelp text="how to rebalance your account" to="/help/account-rebalancing" />

            <br /><br />

            {renameVisible && <RenameAccount id={id} setVisibility={setRenameVisible} />}
            {deleteVisible && <DeleteAccount id={id} setVisibility={setDeleteVisible} />}
            {editNavVisible && <EditAccountNav id={id} setVisibility={setEditNavVisible} />}

            {false && <p>{JSON.stringify(accountInfo)}</p>}
        </Page >
    )
}

export default RebalancePage

//==============================================================================
// end of file
